.index_all {
    width: 100%;
    height: 100vh;
}

.react_pop {
    width: 100%;
    word-wrap: break-word;
}

.home_earnings {
    width: 90%;
    height: 40px;
    margin: 3% 5%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: #FF7003;
    font-size: 15px;
    color: #fff;
}

.home_earnings img {
    width: 24px;
    height: 24px;
}

.home_earnings>p:first-child {
    color: rgba(255, 255, 255, 0.80);
    font-size: 14px;
    font-weight: 500;
}

.user_money {
    min-width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
}

.user_money>p:nth-child(2) {
    margin: 2px 7px 0;
    font-size: 18px;
    font-weight: 600;
}

.user_money>p:last-child {
    font-size: 12px;
    font-weight: 400;
}

.banner {
    width: 90%;
    margin: 10px auto;
    border-radius: 8px;
}

.swiper_item {
    height: 120px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    user-select: none;
}

.swiper_item img {
    width: 100%;
    height: 100%;
}


.notice {
    width: 90%;
    height: 36px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notice .adm-swiper-slide {
    background-color: #E6E6E6;
    border-radius: 8px;
}

.adm-swiper-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notice_swper {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;

}

.index_sever {
    width: 90%;
    margin: 18px auto 0;
}

.teamwork h3,
.index_sever h3 {
    height: 30px;
    margin: 5px 0;
    font-size: 18px;
}

.sever_model {
    width: 100%;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
}

.sever {
    width: 100%;
    height: 100%;
    background-image: url('/public/assets/index/bs3.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    position: relative;
    margin: 0 0 0 5px;
}

.sever>p:first-child {
    padding: 0 5px;
    margin: 10px 0 0 4px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
}

.about_help {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 0 5px 0 0;
}

.about {
    background: url('/public/assets/index/bs1.svg') no-repeat;

}

.help {
    background: url('/public/assets/index/bs2.svg') no-repeat;
}

.ah_model {
    height: 60px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    position: relative;
}

.ah_model p {
    position: absolute;
    left: 36%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: 800;
    text-transform: capitalize;
}



.teamwork {
    width: 90%;
    margin: 20px auto;
    max-height: 185PX;
}

.icon_banner {
    width: 100%;
    height: 125px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.icon_banner img {
    width: 14.3%;
    height: 38%;
    border-radius: 10px;
    margin: 2% 0;
}

.arrow {
    width: 30px;
}

.swiperOne_items {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.banner-items {
    width: 65px;
    border-radius: 10px;
    margin: 5px 10px;
}

.banner-items img {
    width: 100%;
    height: 100%;
    box-shadow: 0px 3px 8px #dadada;
}

.setBadge {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    font-size: 12px;
    border-radius: 50%;
}